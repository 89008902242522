$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('[data-toggle="tooltip"]').tooltip();

    $.fn.select2.amd.require(['select2/selection/search'], function (Search) {
        var oldRemoveChoice = Search.prototype.searchRemoveChoice;

        Search.prototype.searchRemoveChoice = function () {
            oldRemoveChoice.apply(this, arguments);
            this.$search.val('');
        };

        $('select.select2-simple').select2({
            minimumResultsForSearch: Infinity,
            width: 'auto'
        });

        $('select.select2-advanced').select2({
            width: 'auto'
        });
    });

    const imageLink = $('.image-link');
    if (typeof imageLink === 'function') {
        imageLink.magnificPopup({type: 'image'})
    }

    $(document).on('click', '[data-action=switchInterfaceColorMode]', function (e) {
        e.preventDefault();
        $('#formWithPostMethod').append($('<input>', {
            type: 'hidden',
            name: 'mode',
            value: $(this).attr('data-mode')
        }));
        $('#formWithPostMethod').attr('action', $(this).attr('href')).trigger('submit');
    });

    $(document).on('click', '[data-action=submit-filter]', function (e) {
        $(this).closest('.card').find('form').trigger('submit');
    });

    $(document).on('click', '[data-action=userLogout]', function (e) {
        e.preventDefault();
        $('#formWithPostMethod').attr('action', $(this).attr('href')).trigger('submit');
    });

    $(document).on('click', '[data-action=deleteRecord]', function (e) {
        e.preventDefault();

        if (confirm('Are you sure you want to delete this item?')) {
            $('#formWithDeleteMethod').attr('action', $(this).attr('href')).trigger('submit');
        }
    });


    $(document).on('click', '[data-action=deleteStatus]', function () {
        if (confirm('You are sure that you want to delete all planned changes in parking statuses?')) {
            return true;
        }
        return false;
    });

    $(document).on('click', '[data-action=deleteWeightStatus]', function () {
        if (confirm('You are sure that you want to delete all planned changes in weight station statuses?')) {
            return true;
        }
        return false;
    });

    $(document).on('click', '[data-action=deleteRecords]', function (e) {
        e.preventDefault();

        if (confirm('Are you sure you want to delete this items?')) {

            $.each($("input[name='ids[]']:checked"), function () {
                $('<input>', {
                    type: 'hidden',
                    name: 'ids[]',
                    value: $(this).val()
                }).appendTo('#formWithPostMethod');
            });

            $('#formWithPostMethod').attr('action', $(this).attr('href')).trigger('submit');
        }
    });

    $(document).on('submit', '#changeMultipleStatus', function (e) {
        e.preventDefault();
        $.each($("input[name='ids[]']:checked"), function () {
            $('<input>', {
                type: 'hidden',
                name: 'ids[]',
                value: $(this).val()
            }).appendTo('#changeMultipleStatus');
        });

        e.currentTarget.submit();
    });

    $(document).on('change', '[name="ids[]"]', function () {
        if ($("input[name='ids[]']:checked").length) {
            $('button[data-action="multiple"]').attr('disabled', false);
        } else {
            $('button[data-action="multiple"]').attr('disabled', true);
        }
    });

    if ($(document).find('#pagination').length > 0) {
        document.getElementById('pagination').onchange = function () {
            let queryParams = new URLSearchParams(window.location.search);
            queryParams.set("paginate", this.value);
            queryParams.delete("page");

            window.location.search = queryParams;
        };
    }

    $('input[name="check_all"]').change(function () {
        $('input[name="ids[]"]').prop('checked', $(this).prop('checked')).change();
    });
});

$.fn.sorting = function () {
    let $sortableTable = $(this);
    let fixed = '.blocked';
    let newParentContainer;

    let changePosition = function (requestData) {
        $.ajax({
            'url': $sortableTable.attr('data-url'),
            'type': 'POST',
            'data': requestData,
            'success': function (data) {
                //
            },
            'error': function () {
                console.error('Something wrong!');
            }
        });
    };

    let fixHelper = function (e, ui) {
        ui.children().each(function () {
            $(this).width($(this).width());
        });
        return ui;
    };

    function sortingAroundFixedPositions(container) {
        let sortable = $(container);
        let statics = $(fixed, container).detach();
        let tagName = statics.prop('tagName');
        let helper = $('<' + tagName + '/>').prependTo(container);
        statics.each(function () {
            let target = this.dataset.pos;
            let targetPosition = $(tagName, sortable).eq(target);
            if (targetPosition.length === 0) {
                targetPosition = $(tagName, sortable).eq(target - 1)
            }
            $(this).insertAfter(targetPosition);
        });
        helper.remove();
    }

    if ($sortableTable.length > 0) {
        $sortableTable.sortable({
            handle: '.sortable-handle',
            axis: 'y',
            cancel: fixed,
            cursor: "move",

            start: function () {
                $(fixed, $sortableTable).each(function () {
                    this.dataset.pos = $(this).index();
                });
            },
            change: function (e, ui) {
                sortingAroundFixedPositions(this);
                if (ui.sender) {
                    newParentContainer = this;
                }
                if (newParentContainer) {
                    sortingAroundFixedPositions(newParentContainer);
                }
            },

            update: function (a, b) {
                const $sorted = b.item;

                const $previous = $sorted.prev();
                const $next = $sorted.next();

                const customColumn = this.getAttribute('data-custom-column')
                console.log(customColumn)
                if ($previous.length > 0) {
                    changePosition({
                        type: 'moveAfter',
                        id: $sorted.data('itemid'),
                        customColumn,
                        positionEntity: $previous.data('itemid')
                    });
                } else if ($next.length > 0) {
                    changePosition({
                        type: 'moveBefore',
                        customColumn,
                        id: $sorted.data('itemid'),
                        positionEntity: $next.data('itemid')
                    });
                } else {
                    console.error('Something wrong!');
                }
            },
            helper: fixHelper
        });
    }
};

$.fn.select2Sortable = function () {
    let selectEl = $(this);

    selectEl.next().children().children().children().sortable({
        containment: 'parent', stop: function (event, ui) {
            ui.item.parent().children('[title]').each(function () {
                let title = $(this).attr('title');
                let original = $('option:contains(' + title + ')', selectEl).first();
                original.detach();
                selectEl.append(original)
            });
            selectEl.change();
        }
    });
};

$('.custom-file-input').on('change',function(){
    var fileName = $(this)[0].files[0].name;
    $(this).next('.custom-file-label').html(fileName);
})
